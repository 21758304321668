import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import PageLayout from '../../../layouts/Page';
import StudyCurrent from './StudyCurrent';
import Summary from './Summary';
import ThankYouSmallLogo from '../../../images/small_logo.svg';

const Page = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>L'attribution au service de la rémunération de l'affiliation - TY Affiliate</title>
        <meta
          name="description"
          content="Maitrisez la performance réelle de votre programme d'affiliation et rémunérez vos affilié"
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-title text-3xl font-extrabold tracking-tight text-green-brand">
              <div className="relative flex flex-column justify-center items-center">
                <img
                  className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                  src={ThankYouSmallLogo}
                  alt="Thank-You Analytics Logo"
                />
                <span>Affiliate</span>
              </div>
            </h2>
            <p className="mt-4 max-w-6xl mx-auto font-title font-extrabold text-gray-900 text-2xl sm:tracking-tight lg:text-5xl">
              L'attribution au service de la rémunération de l'affiliation
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              Il est temps de réinventer le modèle de rémunération de l'affiliation. Créons ensemble
              le modèle d'attribution reflétant votre stratégie marketing et rémunérez vos affiliés
              en fonction de leur performance réelle.
            </p>
          </div>
        </div>
      </div>
      <StudyCurrent />
      <Summary />
      <div className="bg-white">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-2xl font-title font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Vous souhaitez optimiser votre programme d'affiliation ?</span>
            <span className="block text-green-brand">Faites une demande de démo</span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              to="/contact"
              className="flex items-center justify-center bg-green-brand px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-green-brandHover"
            >
              Demande de démo
            </Link>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Page;
